<template>
  <section>
    <div id="meet"></div>

    <div class="d-flex">
      <div style="width: 12rem" class="ml-0 pl-0">
        <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("FROM") }}</h4>
        {{
          startMeeting == null
            ? FormatDateTime(message.settings.meeting_start_date)
            : FormatDateTime(startMeeting)
        }}
      </div>
      <div style="width: 12rem" class="ml-0 pl-0">
        <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("TO") }}</h4>
        {{
          endMeeting == null
            ? FormatDateTime(message.settings.meeting_end_date)
            : FormatDateTime(endMeeting)
        }}
      </div>
    </div>

    <hr class="pb-0 mb-0 mt-2" v-if="recurrence" />

    <PickRecurrence
      class="mt-2"
      v-if="recurrence"
      :recurrence="recurrence"
      :startDate="message.settings.meeting_start_date"
      :endDate="message.settings.meeting_end_date"
      text-only
    ></PickRecurrence>

    <hr class="pb-0 mb-0 mt-2" v-if="message.attachments.length != 0" />

    <div class="mt-2" v-if="message.attachments.length != 0">
      <span
        v-for="(item, index) in message.attachments"
        :key="index"
        class="ms-fontSize-12"
      >
        <Attachment
          :item="item"
          :index="index"
          :message_uuid="message.message_uuid"
          :user_uuid="user_uuid"
          :filestorage="filestorage"
          :functionbox_uuid="functionbox_uuid"
        ></Attachment>
      </span>
    </div>

    <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("INFORMATION") }}</h4>

    <BodyText :body="message.body"></BodyText>

    <h4 class="m-0 p-0 mt-2 mb-1 link-header">
      {{ $t("MESSAGES.EXTERNAL_TEXT") }}
    </h4>

    <BodyText :body="message.external_text"></BodyText>

    <div v-if="password">
      <h4 class="m-0 p-0 mt-2 mb-1 link-header">
        {{ $t("PASSWORD") }}
      </h4>
      {{ password }}
    </div>

    <hr class="pb-0 mb-0 mt-2 mb-2" />

    <b-button variant="primary" class="btn-fill" @click="OpenMeeting">
      {{ $t("SECURELINK.JITSI.JOIN_MEETING") }}
    </b-button>
  </section>
</template>
<script>
import PickRecurrence from "../../Input/PickRecurrence";
import Attachment from "../Attachment";
import BodyText from "../BodyText";
export default {
  name: "MessageInformation",
  props: [
    "filestorage",
    "message",
    "user_uuid",
    "functionbox_uuid",
    "startMeeting",
    "endMeeting",
    "recurrence"
  ],
  components: {
    Attachment,
    BodyText,
    PickRecurrence,
  },
  data() {
    return {
      opened: false,
      password: "",
    };
  },
  methods: {
    async OpenMeeting() {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/nuiteq/join/" + this.message.message_uuid
        );
        setTimeout(() => {
          window.open(result.data, "_blank").focus();
        });
      } catch {
        console.log("Could not OpenMeeting");
      }
    },
    async GetPassword() {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/nuiteq/password/" + this.message.message_uuid
        );
        this.password = result.data;
      } catch {
        console.log("Could not GetPassword");
      }
    },
  },
  watch: {},
  mounted: function () {
    this.GetPassword();
  },
};
</script>
<style></style>
